import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { isMobile } from "react-device-detect";

export const HistoryPageTemplate = ({ image, html, title }) => {
  return (
    <div>
      <div className="section-container">
        <section className="section">
          <h1>{title}</h1>
        </section>
        <GatsbyImage image={image} alt="Hero image" style={{
          width: "100%",
          height: isMobile ? "300px" : "500px",
        }} />
      </div>
      <div className="section-container">
        <section className="section inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}

HistoryPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  html: PropTypes.string,
}


const HistoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <HistoryPageTemplate image={frontmatter.image.childImageSharp.gatsbyImageData} title={frontmatter.title} html={data.markdownRemark.html} />
    </Layout>
  );
}

HistoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};


export default HistoryPage;

export const pageQuery = graphql`
  query HistoryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "history-page" } }) {
      html
      frontmatter {
        title
        image { 
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        
      }
    }
  }
`


